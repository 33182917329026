$(function(){
    var hgS3=new selectSwiper({
        el:'.select_box',
        mustSelect:false,
        activeIndex:0,
        data:['Indonesian','ENGLISH'],
        init:function(index){$('.select_val').html(this.data[index]);},
        okFun:function(index){$('.select_val').html(this.data[index]);},
        closeFun:function(){console.log('cancel');},
    });
    $('.selectBtn').on('click',function(){hgS3.openSelectSwiper();});
});